@import "styles/variables.scss";
.Wrapper {
  padding-bottom: 24px;
  
  @media (max-width: $mobile-size) {
    padding-bottom: 16px;
  }
}

.Card {
  padding: 24px 24px 0;
  
  @media (max-width: $mobile-size) {
    padding: 16px 16px 0;
  }
}

.Table {
  background: var(--background-color-card);
  padding-left: 24px;
  min-width: min-content;

  &.Border_top {
    border-top: 1px solid var(--border-color-secondary);
  }
  
  a {
    text-decoration: none;
  }
  
  @media screen and (max-width: $mobile-size) {
    padding-left: 16px;
  }
}

.Sort {
  justify-content: center;
  width: min-content;
  justify-self: flex-start;
}

.Gray_back {
  background: var(--background-color-back-table);
}

.Wrap {
  white-space: wrap;
}
