@import "styles/variables.scss";
.container {
  position: relative;
  display: flex;
}

.tooltip {
  position: absolute;
  
  white-space: normal;
  color: $white-color;
  background-color: var(--background-color-tooltip);
  font-size: $font-size-xs;

  padding: 6px;

  justify-content: center;

  border-radius: 0.5rem;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  z-index: 999;
  
  &.top {
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
  }
  
  &.right {
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
  }
  
  &.left {
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
  }
  
  &.bottom {
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
  }
}

.arrow {
  content: '';
  position: absolute;
  border: 5px solid transparent;
  
  &.top {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 5px solid rgba($black-primary, 0.85);
  }
  
  &.right {
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-right: 5px solid rgba($black-primary, 0.85);
  }
  
  &.left {
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-left: 5px solid rgba($black-primary, 0.85);
  }
  
  &.bottom {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 5px solid rgba($black-primary, 0.85);
  }
}

.show {
  opacity: 0;
  animation: ani 0.2s forwards;
}

@keyframes ani {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
