@import "styles/variables.scss";
.ButtonContainer {
  button {
    min-width: 130px;
  }
}

.PublishButton {
  background-color: $toast-success;

  &:hover {
    background-color: $win-color;
  }

  &:disabled {
    background-color: $green-primary;
    opacity: 0.5;
  }
}
