@import "styles/variables.scss";
.Root {
  position: relative;
  width: 100%;
  background-color: var(--background-color-default);
  font-size: 0.75rem;
  color: $grey-primary;
  margin: 0;
  padding: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: calc(100% - 4rem);
    height: 1px;
    background-color: var(--border-color-secondary);
  }

  .Container {
    position: relative;
    margin: 0 16px 60px;
    padding: 2rem 2rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $tablet-size) {
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
    }
  }
}

.Copyright {
  @media (max-width: $tablet-size) {
    order: 3;
  }
}

.Menu {
  color: $black-primary;
  display: flex;
  flex-grow: 1;
  margin-left: 2rem;

  li {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: var(--font-color-primary);
    text-decoration: none;
  }

  @media (max-width: $tablet-size) {
    order: 1;
    margin-left: 0;
    margin-bottom: 1.25rem;
  }

  @media (max-width: $mobile-size) {
    font-size: 1rem;
    margin-bottom: 2rem;
    flex-direction: column;

    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.Icons {
  display: flex;
  align-items: center;

  @media (max-width: $tablet-size) {
    order: 2;
    margin-bottom: 1.25rem;
    margin-left: -0.5rem;
  }

  @media (max-width: $mobile-size) {
    margin-bottom: 2rem;
    margin-left: -0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
}

.List {
  display: flex;

  li {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    text-decoration: none;
  }

  @media (max-width: $mobile-size) {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Devider {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 1px;
  height: 0.875rem;
  background: $grey-primary-2;

  @media (max-width: $mobile-size) {
    display: none;
  }
}
