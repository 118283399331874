@import "styles/variables.scss";
.Root {
  padding: 24px 32px 32px;
  display: block;
  width: 100%;
  max-width: $news-max-width;

  @media (max-width: $index-card-size) {
    padding: 14px 18px 18px;
  }
}

.Header {
  border-bottom: 1px solid $grey-primary-6;
  padding-bottom: 14px;
}

.Delete {
  justify-self: end;
  border: none;
  padding: 4px;
  transition: 0.3s ease-in;
  width: 27px;
  height: 27px;
  cursor: pointer;

  svg {
    transition: 0.3s ease-in;
    fill: $black-primary;
    width: 18px;
    height: 18px;
  }

  &:hover {
    border: none;

    svg {
      transition: 0.3s ease-in;
      fill: $blue-primary;
    }
  }

  &:active {
    border: none;
    background-color: transparent;
  }
}
