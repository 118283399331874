@import "styles/variables.scss";
.Card {
  padding: 24px 24px 0;
  
  @media (max-width: $mobile-size) {
    padding: 16px 16px 0;
  }
}

.Table {
  background: var(--background-color-card);
  padding-left: 24px;
  min-width: min-content;
  
  &.Border_top {
    border-top: 1px solid var(--border-color-secondary);
  }
  
  @media screen and (max-width: $mobile-size) {
    padding-left: 16px;
  }
}

.Positive {
  color: $positive-color;
}

.Negative {
  color: $negative-color;
}

.Wrap {
  white-space: wrap;
}
