@import "styles/variables.scss";
.Author {
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 0;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;

  white-space: nowrap;
  font-weight: 500;
  font-size: $font-size-s;
  color: var(--font-color-primary);
  line-height: $line-height-base;

  @media (max-width: 670px) {
    font-size: $font-size-s;
  }
}

.Panel {
  width: 100%;
}

.Bookmark {
  position: absolute;
  top: 19px;
  right: 18px;
  
  @media (max-width: 530px) {
    right: 10px;
  }
}


.Views {
  font-size: $font-size-s;
  color: var(--font-color-primary);
  font-weight: 600;
  line-height: 1;
}


.ViewsContainer {
  gap: 3px;
}
