@import "styles/variables.scss";
.Root {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.Image {
  object-fit: contain;
  display: block;
  width: 100%;
}

.ImageBackground {
  width: 100%;
  filter: blur(14px) brightness(1);
  opacity: 0.9;
}

.ImageWithBackground {
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ImagePreview{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
