@import "styles/variables.scss";
.StickyColumn {
  position: sticky;
  right: 0;
  padding: 0 24px;
  text-align: right;
  background: inherit;
  display: grid;
  place-items: center center;
  width: 100%;
  height: 100%;
  border-left: 1px solid var(--border-color-secondary);
  
  @media (max-width: $mobile-size) {
    padding: 0 16px;
  }
}
