@import "styles/variables.scss";
.Root {
  
  @media (max-width: $mobile-size) {
    margin-top: -40px;
  }
}

.Card {
  padding: 24px;
  
  @media (max-width: $mobile-size) {
    padding: 16px;
  }
}

.Balance {
  display: flex;
  justify-content: space-between;
  margin-top: 46px;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;

  @media (max-width: $mobile-size) {
    flex-direction: column;
  }
}

.BalanceInfo {
  font-size: 1rem;
}

.History {
  @media (max-width: $mobile-size) {
    font-size: 18px;
  }
}

.Button {
  
  @media (max-width: $mobile-size) {
    width: 50%;
  }
}

.Head {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
}

.Row {
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: $mobile-size) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.Date {
  line-height: 24px;
}

.Name {
  overflow-x: hidden;
  line-height: 24px;
  white-space: nowrap;
}

.Amount {
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  white-space: nowrap;

  @media (max-width: $mobile-size) {
    font-size: 13px;
  }
}

.PositiveAmount {
  composes: Amount;
  color: $positive-color;
}

.NegativeAmount {
  composes: Amount;
}

.ToggleButton {
  padding: 6px 10px 8px 10px;
  background: $background-color;
  border-radius: 9px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.05em;
  color: $grey-primary;
  display: flex;
  align-items: center;
  gap: 20px;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &.active {
    padding: 5px 10px 5px 10px;
    background: $white-color;
    border-radius: 7px;
    color: $black-primary;
  }
}

.Affiliate {
  cursor: pointer;
  
  @media (max-width: $mobile-size) {
    font-size: 16px;
  }
}

.Avatar {
  flex-shrink: 0;
}
