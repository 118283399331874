@import "styles/variables.scss";
.ModalHeader {
  row-gap: 12px;
  margin: 0 auto;
  padding: 0 4rem 1rem 5rem;
  min-height: 48px;
  height: 100%;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  align-items: center;

  @media (max-width: $tablet-size) {
    padding: 0 2rem 1rem 3.5rem;
  }

  @media (max-width: 700px) {
    grid-template-columns: auto auto auto;
  }

  @media (max-width: $index-card-size) {
    padding: 0;
  }

  @media (max-width: $mobile-size) {
    padding: 0 16px;
  }

  @media (max-width: 370px) {
    grid-template-columns: auto;

    div:nth-child(3) {
      margin-left: 20px;
    }
  }
}

.Divider {
  background: $grey-primary-6;
  width: 1px;
  height: 30px;

  @media (max-width: 370px) {
    display: none;
  }
}

.ModalContentWrapper {
  margin: 1rem auto 0;
  max-height: 65lvh;
  min-height: 65lvh;
  transition: hei 0.5s ease;
  overflow-x: hidden;
}

.ModalContent {
  padding: 0 4rem 0.5rem 5rem;

  .MainTitleWrapper textarea {
    font-size: $font-size-xxl;
  }

  @media (max-width: $tablet-size) {
    padding: 0 2rem 1rem 3.5rem;
  }

  @media (max-width: $index-card-size) {
    padding: 0;

    .MainTitleWrapper textarea {
      font-size: $font-size-xxl-mobile;
    }
  }

  @media (max-width: $mobile-size) {
    padding: 0 1rem;
  }
}

.StartReview {
  width: fit-content;
  padding-left: 20px;

  @media (max-width: 700px) {
    grid-row: 2/3;
  }

  @media (max-width: 370px) {
    grid-row: 3/4;
  }
}
