@import "styles/variables.scss";
.sorting_button {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  padding: 3px 5px 4px 6px;
  box-sizing: border-box;
  border: 1px solid $grey-primary-4;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  color: $grey-primary;
  transition: 0.2s;

  img {
    margin-left: 5.5px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.active {
  background: $blue-primary;
  color: $white-color;
  border: 1px solid transparent;
}
