@import "styles/variables.scss";
.Root {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto 1fr;
  column-gap: 8px;
  padding-bottom: 10px;
}

.Root p {
  font-size: $font-size-s;
  margin: 0;

  @media (max-width: $index-card-size) {
    font-size: $font-size-s-mobile;
  }
}

.SportIcon {
  width: 24px;
  height: auto;
}

.Sport {
  padding-right: 19px;
  position: relative;
  color: var(--font-color-primary);

  &:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 1px;
    background-color: $grey-primary-6;
    right: 6px;
  }

  @media (max-width: 450px) {
    padding-right: 6px;

    &:after {
      right: -1px;
    }
  }
}

.Category{
  color: var(--font-color-primary);
}

.Date {
  color: $grey-primary;
  padding-left: 12px;
}

.Actions {
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 32px;
}
