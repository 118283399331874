@import "styles/variables.scss";
.Card {
  padding: 24px 24px 0;
  
  @media (max-width: $mobile-size) {
    padding: 16px 16px 0;
  }
}

.Pagination {
  padding-bottom: 24px;
  
  @media (max-width: $mobile-size) {
    padding-bottom: 16px;
  }
}
