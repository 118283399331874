@import "styles/variables.scss";
.Select {
  width: 160px;

  div:first-of-type {
    border: none;
  }

  li {
    padding: 13px;
  }
}
