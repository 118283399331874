@import "styles/variables.scss";
.ModalContainer {
  height: 60vh;
  max-height: 600px;
  overflow-y: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 32px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.Avatar {
  object-fit: cover;
  flex-shrink: 0;
}

.UploadInfo {
  color: $grey-primary;
  text-align: center;
  max-width: 70%;
  line-height: 140%;

  span {
    color: $blue-disabled;
    text-transform: uppercase;
  }
}

.Button {
  width: 90px;
  margin: 0;
}
