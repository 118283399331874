@import "styles/variables.scss";
.Root {
  cursor: pointer;
  width: fit-content;
}


.AvatarContainer {
  position: relative;
  flex-shrink: 0;

  @media (hover: hover) and (pointer: fine) {
    &::after {
      transition: 0.5s;
      content: url('./upload.svg');
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $white-color;
      padding: 11px 14px;
      border-radius: 7px;
      display: none;
    }

    &:hover::after {
      display: block;
    }

    &:hover::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($black-color, 0.4);
      border-radius: 10px;
    }

    &:hover svg {
      display: none;
    }
  }
}

.Cross {
  margin-top: -5px;
  flex-shrink: 0;
  width: 28px;
  align-self: flex-start;

  @media (hover: none) {
    display: none;
  }
}
