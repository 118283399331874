@import "styles/variables.scss";
.Card {
  padding: 24px 24px 0;
  
  @media (max-width: $mobile-size) {
    padding: 16px 16px 0;
  }
}

.Pagination {
  padding-bottom: 24px;
  
  @media (max-width: $mobile-size) {
    padding-bottom: 16px;
  }
}

.Table {
  background: var(--background-color-card);
  padding-left: 24px;
  min-width: min-content;

  &.Border_top {
    border-top: 1px solid var(--border-color-secondary);
  }
  
  a {
    text-decoration: none;
  }
  
  @media screen and (max-width: $mobile-size) {
    padding-left: 16px;
  }
}

.Sort {
  justify-content: center;
  width: min-content;
  justify-self: center;
  
  &.Date {
    justify-self: flex-start;
  }
}

.WinAmountWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.Icon {
  position: absolute;
  left: -25px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.ArrowIconOpen {
  transform: rotate(90deg);
}

.NoPaddingStickyColumn{
  padding: 0;
}

.Gray_back {
  background: var(--background-color-back-table);
}

.Event {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 16px;
  color: var(--font-color-primary);
  
  @media screen and (max-width: $mobile-size) {
    font-size: 13px;
  }
}
