@import "styles/variables.scss";
.themeToggle {
  position: relative;
  display: flex;
  align-items: center;
  background: gray;
}


.circleClassName{
  width: 24px;
  background: $grey-primary-2;
  transition: transform 0.2s ease-in-out;
  z-index: 2;
}



.icon {
  width: 20px;
  height: 20px;
}