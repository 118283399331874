@import "styles/variables.scss";
.Root {
  @media (max-width: 900px) {
    width: 220px;
    margin-left: 36px;
  }
}

.Container {
  display: grid;
  gap: 8px;
  padding: 20px 32px 16px;
  grid-template-columns: 170px 1fr 1fr 178px 130px;
  align-items: center;

  @media (max-width: 900px) {
    grid-template-columns: 100%;
  }
}

.ContainerMobile {
  display: none;
}

.FiltersTitle {
  margin: 0;
  padding: 20px 32px 16px;
  font-size: 20px;
  font-weight: 600;
}

.Filter {
  position: relative;
  width: fit-content;
}

.Label {
  color: var(--font-color-primary);
  font-size: 18px;
  gap: 10px;
  transition: 0.3s;
  font-weight: 600;

  svg {
    fill: var(--font-color-primary);
  }

  &:hover {
    color: $blue-hover;

    svg {
      fill: $blue-hover;
    }
  }

  @media (max-width: 900px) {
    font-weight: 400;
  }
}

.FilterItemsContainer {
  position: absolute;
  background: var(--background-color-select);
  margin-top: 8px;
  border-radius: 0.5em;
  padding: 14px;
  color: var(--font-color-primary);
  border: 1px solid $grey-primary-2;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);

  @media (max-width: 900px) {
    position: static;
    border: none;
    box-shadow: none;
  }
}

.DataItems {
  left: -22px;
}

.ResetButton {
  border: none;
  font-size: 17px;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 4px 6px;
  width: fit-content;
  transition: 0.3s;
  color: $blue-primary;

  &:hover {
    color: $blue-hover;
  }

  &:active {
    color: $blue-click;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.FilterCount {
  position: absolute;
  top: -14px;
  right: -20px;
  font-weight: 500;
  font-size: 13px;
  border-radius: 50%;
  background: $blue-primary;
  color: $white-color;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    top: 0;
    right: -2px;
  }
}

.StatusContainer {
  width: 178px;
}

.DateContainer {
  width: 170px;
}

.CategoryItemsContainer, .SportItemsContainer {
  width: 160px;
}
