@import "styles/variables.scss";
.Description {
  margin: 0;
  color: $grey-primary-7;
  font-size: $font-size-s;
  line-height: $line-height-base;
}

.Input {
  display: none;
}

.PreviewImage {
  max-width: 10rem;
  object-fit: cover;
}

.UploadButton {
  width: 130px;
}


.Container {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid $grey-primary;

  &:focus-within {
    border-color: $black-primary;
  }
}

.CharsCountLimit {
  border: 1px solid $red-primary;

  &:focus-within {
    border: 1px solid $red-primary;
  }
}

.Textarea {
  width: 100%;
  font-size: $font-size-base;
  font-weight: 400;
  padding: 0 4px 0 0;
  resize: vertical;
  min-width: 100%;
  min-height: 30px;
  max-height: 200px;
  outline: none;
  border: none;

  @media (max-width: $mobile-size) {
    font-size: $font-size-base-mobile;
  }
}

@mixin label {
  margin: 0;
  color: $grey-primary-7;
  font-size: $font-size-s-2;
}

.Title {
  @include label;
}

.ValueCharsCount {
  @include label;
  align-self: flex-end;
}

.ValueCharsCountLimit {
  color: $red-primary;
}