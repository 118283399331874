@import "styles/variables.scss";
.Button {
  height: unset;
  padding: 3px 14px;
  color: var(--font-color-primary);
  background: var(--background-color-button);

  &:hover{
    color: var(--font-color-hover);
  }
}
