@import "styles/variables.scss";
.Root {
  padding: 0 32px;
  border-bottom: $divider;
  color: var(--font-color-primary);
  min-width: 980px;

  li {
    padding: 16px 0;
    overflow: hidden;
  }

  & > div:nth-child(2) {
    padding-top: 0;

    > div {
      padding: 0;
    }
  }
}

.Author {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}

.Label {
  margin: 0;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Date {
  font-size: 14px;
}

.Title {
  color: var(--font-color-primary);
  font-size: 16px;
  overflow-wrap: break-word;
}

.Arrow {
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-items: center;
  align-items: flex-start;
  cursor: pointer;
}

.ArrowUp {
  transform: rotate(180deg);
}

.ReviewStatusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReviewStatus {
  padding: 2px 6px 4px;
  margin: 0;
  font-size: 14px;
  color: var(--font-color-interactive);
  border-radius: 6px;
  text-align: center;
  width: min-content;
}

.WaitingModeration {
  background-color: var(--status-color-draft);
}

.MyReview {
  background-color: var(--status-color-review)
}

.OtherReview {
  background-color: var(--status-color-moderating)
}
