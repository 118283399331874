@import "styles/variables.scss";
.Root {
  position: absolute;
  top: 55px;
  left: 0;
  width: 500px;
  border-radius: 10px;
  column-count: 2;
  height: fit-content;
  background-color: var(--background-color-secondary);
  padding: 36px 20px 20px;
  box-shadow: 1px 2px 5px 3px rgba($black-color, 0.08);
  z-index: 20;

  @media (max-width: 1230px) {
    display: none;
  }
}

.Title {
  position: absolute;
  margin: 0;
  top: 13px;
  font-size: 12px;
  color: $grey-primary;
}

.Item {
  white-space: nowrap;
  font-size: 16px;
  line-height: 250%;
  color: var(--font-color-primary);
  cursor: pointer;
  padding: 0 12px;
  border-radius: 4px;

  & a {
    text-decoration: none;
    color: $black-primary;
  }

  &:not(.ItemActive):hover {
    background-color: $red-primary;
    color: $white-color;
  }
}

.ItemActive {
  background-color: var(--background-color-select-active);
  cursor: default;
}
