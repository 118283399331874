@import "styles/variables.scss";
.Root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: var(--background-color-header);
  padding-left: 20px;
  padding-right: 14px;
  position: relative;

  @media (max-width: $mobile-size) {
    height: 56px;
  }
}

.Nav {
  width: 100%;
  position: relative;
}

.Menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 12px;
  @media (max-width: 375px) {
    display: flex;
    gap: 0;
  }
}

.SubMenu {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 12px;
}

.SportsMenu {
  margin-right: 12px;
}

.MenuItem {
  color: $white-color;
  cursor: pointer;
  position: relative;

  & a {
    text-decoration: none;
    color: $white-color;
    display: block;
  }
}

.SportsItem {
  transition: $transition;
  
  &:hover {
    color: $red-hover;
  }
  
  &.Active {
    color: $red-primary;
  }
}

.Hide {
  display: none;
}

.AlignRight {
  justify-self: end;
}

.Logo {
  position: relative;
  width: 167px;
  height: 34px;
  margin-right: 20px;
  background-image: image-set(
                  url('../assets/logo/logo.png') 1x,
                  url('../assets/logo/logo@2x.png') 2x,
                  url('../assets/logo/logo@3x.png') 3x
  );
  cursor: pointer;

  &::after {
    position: absolute;
    right: -12px;
    bottom: -4px;
    content: '';
    background-image: url('/images/beta.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 14px;
  }

  @media (max-width: $mobile-size) {
    width: 128px;
    height: 26px;
    background-image: image-set(
                    url('../assets/logo/logo-mobile.png') 1x,
                    url('../assets/logo/logo-mobile@2x.png') 2x,
                    url('../assets/logo/logo-mobile@3x.png') 3x
    );


  &::after {
    bottom: -6px;
  }
  }

  @media (max-width: 375px) {
    width: 117px;
    height: 23px;
    margin-right: 0;
    background-image: image-set(
                    url('../assets/logo/logo-mobile-small-size.png') 1x,
    );
  }
}

.SportsMenuButton {
  display: flex;
  column-gap: 6px;
  align-items: center;

  svg {
    fill: $grey-primary;
  }

  svg:last-child {
    margin-top: 4px;
  }
}

.IconRotate {
  transition: 0.4s ease;
  transform: rotate(180deg);
}

.HowVote {
  color: $grey-primary;
}

.CreateNewsButton {
  letter-spacing: 0.55px;
  text-transform: uppercase;
  font-size: 12px;
  color: $grey-primary-4;
  border-radius: 7px;
  border: 1px solid $grey-primary-7;
  height: auto;
  padding: 5px 9px;
  background-color: transparent;
  font-weight: 500;

  &:hover {
    background-color: rgba(#999999, 0.15);
  }
}

.CupBannerContainer{
  cursor: pointer;
  margin-left: 10px;
  @media (max-width: 680px) {
    display: flex;
  }
}

.Balance {
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ProfileMenuButton {
  margin-left: 12px;
  display: flex;
  column-gap: 4px;
  justify-self: end;

  @media (max-width: 375px) {
    margin-left: 0;
  }
}

.ArrowDownIcon {
  fill: $white-color;
  width: 18px;
  height: auto;
  display: block;
  transition: 0.4s ease;
}

.LoginButton {
  border: 1px solid $white-color;
  border-radius: 7px;
  color: $white-color;
  background: transparent;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: $transition;
  white-space: nowrap;
  
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 520px) {
    padding: 0.5rem 0.5rem;

  }
  @media (max-width: 375px) {
    padding: 0.5rem 0.5rem;

  }
}
