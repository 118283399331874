@import "styles/variables.scss";
$mobile-size: 540px;

.Root {
  padding: 16px 24px 12px;
  position: relative;
  
  @media (max-width: $mobile-size) {
    padding: 16px 16px 12px
  }
}

.Pin {
  position: absolute;
  right: 5px;
  top: 5px;
}

.previewUploadDescription{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Author {
  font-weight: 500;
  font-size: $font-size-s;
  margin: 0;
  padding-right: 28px;
  line-height: $line-height-xs;

  @media (max-width: $index-card-size) {
    font-size: $font-size-s-mobile;
  }
}

.FavoritesIcon {
  margin-top: 3px;
  cursor: pointer;
}

.NewsFooter {
  padding-top: 14px;
}
