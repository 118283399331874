@import "styles/variables.scss";
.MobileMenuOverPage {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
}

.FullAvatarOverPage {
  background: rgba($black-color, 0.61);
  z-index: 10;
}

.FullAvatar {
  position: absolute;
  max-width: 85vw;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Cross {
  transform: translate(90vw);
  width: 64px;
  height: 64px;
  fill: $grey-primary-5;
  position: absolute;
  left: -24px;
}

.MobileMenu {
  background: $white-color;
  position: absolute;
  box-shadow: 1px 2px 5px 3px rgba($black-color, 0.08);
  width: fit-content;
  padding: 10px;
  border-radius: 7px;
}

@mixin slideInFromLeft($distance, $animationName) {
  @keyframes #{$animationName} {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX($distance);
    }
  }
}

@include slideInFromLeft(90px, 'slideInFromLeft90');
@include slideInFromLeft(120px, 'slideInFromLeft120');

.AnimationM {
  animation: slideInFromLeft90 0.5s ease forwards;
}

.AnimationL {
  animation: slideInFromLeft120 0.5s ease forwards;
}

.MenuItem {
  display: flex;
  align-items: center;
  column-gap: 6px;
  border: none;
  background: transparent;
  margin: 4px;
  font-size: 14px;
  padding: 4px 0;


  &:disabled {
    svg {
      fill: $grey-primary;
    }
  }
}

.Trash {
  margin-right: 3px;
  height: 18px;
}
