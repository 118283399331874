@import "styles/variables.scss";
.tooltip {
  
  @media screen and (max-width: $mobile-size) {
    &[data-state='open'] {
      & .question {
        color: $white-color;
        background-color: #2873db;
        border: 1px solid #2873db;
      }
    }
    
    &[data-state='closed'] {
      & .question {
        color: var(--font-color-primary);
        background-color: unset;
        border: 1px solid $grey-primary-2;
      }
    }
  }
}

.question {
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  
  padding: 1.5px 6px;
  color: $black-primary;
  background-color: unset;
  border: 1px solid $grey-primary-2;

  border-radius: 50%;
  
  transition: $transition;
  
  &.hoverable {
    opacity: 0;
    color: var(--font-color-primary);
    
    &:hover {
      color: $white-color;
      background-color: #2873db;
      border: 1px solid #2873db;
    }
  }
}

.root {
  display: flex;
  align-items: center;
  gap: 4px;
  
  &:hover {
    & .question {
      opacity: 1;
    }
  }
}
