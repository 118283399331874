@import "styles/variables.scss";
.Root {
  overflow: hidden;
  cursor: pointer;
  min-height: 36px;
}

.Date {
  margin: 0;
  padding: 0;
  font-size: $font-size-s;

  @media (max-width: $index-card-size) {
    font-size: $font-size-s-mobile;
  }
}

.Title {
  margin: 0;
  font-size: $font-size-xl;
  color: var(--font-color-primary);
  padding-bottom: 10px;
  font-weight: 600;
  line-height: $line-height-base;
  cursor: pointer;
  overflow-wrap: anywhere;

  @media (max-width: $index-card-size) {
    font-size: $font-size-xl-mobile;
  }
}

.Description {
  margin: 0;
  padding-bottom: 16px;
  color: var(--font-color-primary);
  font-size: $font-size-base;
  overflow-wrap: anywhere;

  @media (max-width: $index-card-size) {
    font-size: $font-size-base-mobile;
  }
}

.previewUploadDescription{
  display: flex;
  flex-direction: column;
  gap: 20px;
}