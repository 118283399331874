@import "styles/variables.scss";
.OverPage {
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 52px;
}

.Menu {
  position: absolute;
  width: 184px;
  top: 66px;
  right: 0;
  background-color: var(--background-color-secondary);
  padding: 14px;
  border-radius: 7px;
  box-shadow: 1px 2px 5px 3px rgba($black-color, 0.08);
  z-index: 20;

  @media (max-width: $mobile-size) {
    top: 56px;
  }
}

.Item {
  flex-shrink: 0;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 4px;
  color: var(--font-color-primary);

  &:not(.UserName):hover {
    background-color: var(--background-color-select-active);
  }
}

.UserName {
  cursor: default;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Logout {
  border-top: 1px solid $grey-primary-6;
  padding-top: 12px;
  color: $grey-primary;
}
