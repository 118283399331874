@import "styles/variables.scss";
@import 'styles/mixsins';

.Root {
  overflow-x: auto;
}

.Wrapper {
  width: 980px;
}

.Header {
  padding: 18px 32px 14px;
  border-bottom: $divider;

  div {
    @include tableHeader;
  }
}

.NoNews {
  text-align: center;
  font-size: $font-size-base;
}

.Pagination {
  @media (max-width: 900px) {
    margin-left: 32px;
  }
}
