@import "styles/variables.scss";
.PreviewVideo {
  position: relative;
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  border-radius: 0.75rem;
}

.BlurBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;

  .VideoBackground {
    filter: blur(14px) brightness(1);
    opacity: 0.9;
    transform: scale(1.2);
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.VideoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.Video {
  position: relative;
  z-index: 3;
  border-radius: 0.75rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.VideoWithBackground {
  object-fit: contain;
}
