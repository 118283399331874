@import "styles/variables.scss";
.Select {
  width: 180px;

  div:first-of-type {
    column-gap: 8px;
    justify-content: start;
    border: none;
  }

  div > div > img {
    height: 34px;
    width: 34px;
  }

  li > div > img {
    height: 24px;
    width: 24px;
  }
}
