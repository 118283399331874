@import "styles/variables.scss";
.Status {
  text-transform: uppercase;
  justify-self: end;
  padding: 6px;
  color: var(--font-color-interactive);
  border-radius: 0.4rem;
  font-size: $font-size-xs;

  @media (max-width: $index-card-size) {
    font-size: $font-size-xs-mobile;
  }
}

.StatusDraft {
  background-color: var(--status-color-draft);
}

.StatusModerating {
  background-color: var(--status-color-moderating)
}

.StatusRejected {
  background-color: var(--status-color-rejected)
}
