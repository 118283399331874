@import "styles/variables.scss";
.challenger__block {
  cursor: pointer;
  align-items: center;
  background: $white-color;
  border: 1px solid hsla(0, 0%, 80%, 0.5);
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  flex-direction: column;
  justify-content: center;
  padding: 23px;
  position: relative;
  transition: 0.2s;
  width: 100%;
  height: fit-content;
  filter: grayscale(0);
  min-width: 221px;
  max-width: 221px;
}

.opened {
  z-index: 1;
}

.block__item {
  width: 56px;
  height: 56px;
  border-radius: 48px;
  border: 1px solid rgba(204, 204, 204, 1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.challenger__block__item {
  opacity: 1;
  text-align: center;
  position: absolute;
  background: white;
  left: -1px;
  top: calc(100% - 11px);
  width: calc(100% + 2px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid hsla(0deg, 0%, 80%, 0.5);
  border-top: none;
  padding: 0 23px 23px;
}

.inactive {
  filter: grayscale(1);
}

.active {
  filter: grayscale(0);
}
