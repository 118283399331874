@import "styles/variables.scss";
.Root {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: $blue-primary;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  & svg {
    fill: $blue-primary;
  }
}

.Arrow {
  display: flex;
  align-items: center;
  transition: transform linear 0.1s;
}

.ArrowOpen {
  composes: Arrow;
  transform: rotate(180deg);
}

.Accordin {
  transition: all linear 0.1s;
  transform: scaleY(0);
  transform-origin: top;
}
