@import "styles/variables.scss";
.Root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  width: 100%;
}

.Main {
  margin: 0 40px;
  flex-grow: 1;
  width: 100%;
  background: var(--background-color-default);

  @media (max-width: $mobile-size) {
    margin: 0;
  }
}
