@import "styles/variables.scss";
.ModalRoot{
  height: 40dvh;

  @media (max-width: $mobile-size) {
    height: 55dvh;
  }
}

.ContentContainer{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 2rem;

}

.NewsHeaderTitle{
  padding: 20px;
  border-radius: 20px;
  background-color: var(--background-color-default);
}

.TextNews{
  color: var(--font-color-primary);
}

.ButtonControl{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: $mobile-size) {
   display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
}

.Button{
  text-transform: capitalize;
  color: var(--font-color-vote-button-secondary);
  border: 1px solid $red-primary;
  background-color: var(--background-color-red-button-widgets);

  @media (max-width: $mobile-size) {
   width: 100%;
  }

  &:hover {
    color: $white-color;
    background-color: var(--background-color-button-widgets-vote-pari-hover);
  }
}