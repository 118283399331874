@import "styles/variables.scss";
.ConfirmModalContainer {
  padding: 0 32px;
  min-height: 420px;

  p {
    padding: 16px 0;
    margin: 0;
  }

  @media (max-width: $index-card-size) {
    padding: 0 10px;
  }
}

.Title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 26px;
}
