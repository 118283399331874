@import "styles/variables.scss";
.Root {
  position: relative;
  display: inline-flex;
  background: var(--background-color-switch);
  padding: 2px;
  border-radius: 0.75rem;
  height: 1.375rem;
  cursor: pointer;
}

.RootSingle {
  composes: Root;

  & .Item {
    width: 100%;
  }

  & .Switch {
    width: calc(100% - 4px);
  }
}

.Item {
  padding: 2px 4px;
  font-weight: 600;
  font-size: 0.6875rem;
  line-height: 150%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: $grey-primary-4;
  width: 50%;
}

.ItemActive {
  composes: Item;
  color: $blue-primary;
}

.Switch {
  position: absolute;
  width: calc(50% - 2px);
  height: calc(100% - 4px);
  background: $white-color;
  border-radius: calc(0.75rem - 2px);
  top: 2px;
  transition: left linear 0.1s;
}
