@import "styles/variables.scss";
.headline {
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: var(--font-color-primary);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.small {
  font-size: 20px;
}

.medium {
  font-size: 22px;

  @media (max-width: $mobile-size) {
    font-size: 20px;
  }
}

.large {
  font-size: 24px;
  @media (max-width: $mobile-size) {
    font-size: 22px;
  }
}
