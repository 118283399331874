@import "styles/variables.scss";
.Table {
  background: var(--background-color-card);
  padding-left: 24px;
  min-width: min-content;

  &.Border_top {
    border-top: 1px solid var(--border-color-secondary);
  }
  
  a {
    text-decoration: none;
  }
  
  @media screen and (max-width: $mobile-size) {
    padding-left: 16px;
  }
}

.Gray_back {
  background: var(--background-color-back-table);
}

.Trash {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  
  & svg {
    transition: $transition;
  }
  
  &:hover,:disabled {
    svg {
      fill: $grey-primary;
    }
  }
}

.Wrap {
  white-space: wrap;
}

.Sort {
  justify-self: flex-start;
}
