@import "styles/variables.scss";
.radio {
  display: flex;
  gap: 8px;
  cursor: pointer;
  flex-shrink: 1;

  .radio__btn {
    width: 25px;
    height: 25px;
    background: $white-color;
    border: 1px solid $grey-primary-5;
    border-radius: 50%;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-shrink: 0;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &.active {
      background: $blue-primary;
    }

    .radio__btn_checked {
      width: 10px;
      height: 10px;
      background: $white-color;
      border-radius: 50%;
    }
  }

  .radio__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $black-primary;
  }
}
