@import "styles/variables.scss";
.BurgerMenu {
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  transition: transform 0.5s;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 3px 0;
  margin-right: 16px;
  @media (max-width: 375px) {
   margin-right: 0;
  }
}

.BurgerMenuItem {
  display: block;
  width: 100%;
  height: 3px;
  background-color: $white-color;
  transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
  border-radius: 6px;
}

.BurgerMenuCross .BurgerMenuItem {
  &:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  &:nth-child(2) {
    opacity: 0;
  }

  &:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

.OverPage {
  @media (max-width: 1230px) {
    position: absolute;
    width: 100vw;
    background: rgba($black-primary, 0.79);
    height: 100vh;
    z-index: 10;
    top: 52px;
  }

  @media (max-width: 450px) {
    display: none;
  }
}

.MenuContainer {
  display: flex;
  width: 400px;
  position: fixed;
  top: 64px;
  height: 100%;
  background-color: $white-color;
  z-index: 100;
  left: 0;

  @media (max-width: 450px) {
    width: 100%;
    top: 56px;
  }
}

.Menu {
  flex-shrink: 0;
  border: none;
  display: flex;
  flex-direction: column;
  background-color: $black-primary;
  width: 70px;
  align-items: center;
  height: 100%;

  .MenuItem:first-child {
    padding-top: 8px;
    border-top: 1px solid $grey-primary-7;
  }
}

.MenuItem {
  display: flex;
  row-gap: 6px;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid $grey-primary-7;
  padding: 9px 0;
  width: 100%;
  cursor: pointer;
}

.MenuItemActive {
  background-color: $red-primary;
}

.MenuItemText {
  font-size: 12px;
  color: $white-color;
  text-decoration: none;
  text-align: center;
  margin: 0;
}

.MenuItemIcon {
  fill: $white-color;
}

.CurrentLanguageIconContainer {
  svg {
    width: 34px;
    border: 1px solid $black-primary;
    border-radius: 3px;
  }
}

.Submenu {
  width: 100%;
  padding: 0;
  background: var(--background-color-card);
  overflow-x: auto;
}

.SubmenuItem {
  display: flex;
  column-gap: 6px;
  padding: 14px;
  width: 100%;
  font-size: 14px;
  color: var(--font-color-primary);
  border-bottom: 1px solid var(--border-color-secondary);
  cursor: pointer;
  word-break: break-all;

  svg {
    width: 18px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.SubmenuItemActive {
  background-color: var(--background-color-select-active);
  pointer-events: none;

  &:hover {
    text-decoration: none;
  }
}
